import { template as template_fb2f8e448e0c4063ba61e083c5363e83 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fb2f8e448e0c4063ba61e083c5363e83(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
