import { template as template_de55fbb78b124b02a155738bc5c2a954 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_de55fbb78b124b02a155738bc5c2a954(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
