import { template as template_15abce2637574bcf8d3f927456f763bd } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_15abce2637574bcf8d3f927456f763bd(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
